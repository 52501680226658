import { Suspense, lazy } from 'react';
import 'prismjs';
import Spinner from './@core/components/spinner/Fallback-spinner';
import { ThemeContext } from './utility/context/ThemeColors';
import 'react-perfect-scrollbar/dist/css/styles.css';
import '@styles/react/libs/toastify/toastify.scss';
import './@core/assets/fonts/feather/iconfont.css';
import * as serviceWorker from './serviceWorker';
import { store } from './redux/storeConfig/store';
import { ToastContainer } from 'react-toastify';
import './@core/components/ripple-button';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';
import { Provider } from 'react-redux';
import './assets/scss/style.scss';
import ReactDOM from 'react-dom';
import './@core/scss/core.scss';

const LazyApp = lazy(() => import('./App'));

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={<Spinner />}>
            <ThemeContext>
                <LazyApp />
                <ToastContainer newestOnTop />
            </ThemeContext>
        </Suspense>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
